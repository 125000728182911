<template>
  <div
    class="text-md font-semibold relative"
  >
    <button
      @mouseover="
        showCategoriesDropdown = true
      "
      @mouseleave="
        showCategoriesDropdown = false
      "
      :class="[
        'flex items-center hover:text-a-secondary mx-4 cursor-pointer focus:outline-none ',
        showCategoriesDropdown
          ? 'text-a-secondary'
          : '',

      ]"
      id="classifiedCategoriesDropdownButton"
    >
      <span
        class="font-semibold tracking-wide"
        >{{ dropdownTitle }}</span
      >
      <Icon
          :name="showCategoriesDropdown
            ? 'mdi:chevron-up'
            : 'mdi:chevron-down'
          "
        class="text-2xl"
      />
    </button>
    <div
      @mouseover="
        showCategoriesDropdown = true
      "
      @mouseleave="
        showCategoriesDropdown = false
      "
      :class="[
        'absolute left-0 z-50 bg-white w-64 shadow-2xl rounded pt-4',
        showCategoriesDropdown
          ? 'block'
          : 'hidden',
      ]"
    >
      <template v-if="isMultilevel">
        <div
          class="w-auto relative rounded"
          v-for="category in categories"
          :key="category.id"
          @mouseover="
            openSubMenu($event)
          "
          @mouseleave="
            closeSubMenu($event)
          "
        >
          <div
            class="flex group items-center border-b border-t border-transparent hover:border-a-neutral-dim py-1 px-2 hover:text-a-secondary cursor-pointer"
            :class="currentSelectedCategoryId===category.id.toString()?'text-a-secondary':'text-a-neutral-darkest'"
            @click="
              $emit(
                'selectedCategory',
                category
              )
            "
          >
            {{ category.title }}
            <Icon
              v-if="
                category.descendants
                  .length > 0
              "
              name="mdi:chevron-right"
              class="text-lg mr-0 ml-auto group-hover:text-a-secondary"
            />
          </div>
          <div
            class="hidden absolute w-64 top-0 left-64 h-auto pb-4 bg-white rounded"
          >
            <p
              class="w-full px-4 py-1 text-sm hover:text-a-secondary font-nor cursor-pointer"
              v-for="subCategory in category.descendants"
              :key="subCategory.id"
              @click="
                $emit(
                  'selectedCategory',
                  subCategory
                )
              "
            >
              {{ subCategory.title }}
            </p>
          </div>
        </div>
      </template>
      <template v-else>
        <div
          class="w-auto relative rounded"
          v-for="category in categories"
          :key="category.id"
        >
          <div
            class="flex group items-center border-b border-t border-transparent hover:border-a-neutral-dim py-1 px-2 hover:text-a-secondary cursor-pointer"
            :class="currentSelectedCategoryId===category.id.toString()?'text-a-secondary':'text-a-neutral-darkest'"
            @click="
              $emit(
                'selectedCategory',
                category
              )
            "
          >
            {{ category.title }}
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
defineEmits(["selectedCategory"]);
const props = defineProps({
  categories: {
    type: Array,
    required: true,
  },
  dropdownTitle: {
    type: String,
    default: "All Categories",
  },
  isMultilevel: {
    type: Boolean,
    default: false,
  },
  currentSelectedCategoryId:{
    type: String,
    required: false
  }
});

const showCategoriesDropdown =
  ref(false);

function openSubMenu(event) {
  let subMenu =
    event.target.parentNode.lastChild;
  if (
    subMenu.children &&
    subMenu.children.length > 0
  ) {
    subMenu.classList.add("flex-col");
    subMenu.classList.remove("hidden");
    event.target.classList.add(
      "text-a-secondary"
    );
    event.target.classList.remove(
      "border-transparent"
    );
    event.target.classList.add(
      "border-a-neutral-dim"
    );
  }
}
function closeSubMenu(event) {
  var subMenu = event.target.lastChild;
  subMenu.classList.add("hidden");
  subMenu.classList.remove("flex-col");
  event.target.firstChild.classList.remove(
    "text-a-secondary"
  );
  event.target.firstChild.classList.add(
    "border-transparent"
  );
  event.target.firstChild.classList.remove(
    "border-a-neutral-dim"
  );
}
</script>

<style></style>
